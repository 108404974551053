import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import "./CategoriasPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";

class CategoriasPage extends React.Component {
  render() {
    return (
      <div className="Media">
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Media-hero"
          style={{
            height: "150px",
            width: "100%",
          }}
        ></section>
        <div className="row">
          <div className="col-12">
            <h4 className="categorias-title">Categorías</h4>
            <p className="categorias-subtitle text-center">
              Los participantes se pueden inscribir en equipos masculinos,
              femeninos o mixtos. Las categorías disponiles son las siguientes
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 col-md-3 categoria-type">
              <h5>Parejas</h5>
            </div>
            <div className="col-12 col-md-9 categoria-description">
              <ul>
                <li><strong>Parejas Élite/Sub-23:</strong> Conforman esta categoría, todas las parejas masculinas en las que al menos uno de sus participantes tenga entre 18 y 29 años o posee una LICENCIA ÉLITE/SUB-23 a día 31 de diciembre de 2025.</li>
                <li><strong>Parejas Máster 30 masculina:</strong> Conforman esta categoría, todas las parejas masculinas en las que al menos uno de sus participantes tenga la edad entre 30 y 39 años a día 31 de diciembre de 2025.</li>
                <li><strong>Parejas Máster 40 masculino:</strong> Conforman esta categoría, todas las parejas masculinas, en las que al menos uno de sus participantes tenga entre 40 y 49 años a día 31 de diciembre de 2025.</li>
                <li><strong>Parejas Máster 50 masculino:</strong> Conforman esta categoría, todas las parejas masculinas, en las que al menos uno de sus participantes tenga 50 o más años a día 31 de diciembre de 2025.</li>
                <li><strong>Parejas Máster 60 masculino:</strong> Conforman esta categoría, todas las parejas masculinas, en las que al menos uno de sus participantes tenga 60 o más años a día 31 de diciembre de 2025.</li>
                <li><strong>Parejas femenino:</strong> Conforman esta categoría, todas las parejas femeninas, independientemente de su edad</li>
                <li><strong>Pareja mixta:</strong> Conforma esta categoría, todas las parejas compuestas por un hombre y una mujer, independientemente de su edad.</li>
              </ul>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-3 categoria-type">
              <h5>Individual</h5>
            </div>
            <div className="col-12 col-md-9 categoria-description">
              <ul>
                <li><strong>Individual Élite/Sub-23 masculino:</strong> Conforma esta categoría, todos los participantes masculinos que tengan entre 18 y 29 años a 31 de diciembre de 2025 o sean poseedores de una licencia Élite/Sub-23.</li>
                <li><strong>Individual Máster 30 masculino:</strong> Conforma esta categoría, todos los participantes masculinos que tengan entre 30 y 39 años a 31 de diciembre de 2025</li>
                <li><strong>Individual Máster 40 masculino:</strong> Conforma esta categoría, todos los participantes masculinos que tengan entre 40 y 49 años a 31 de diciembre de 2025</li>
                <li><strong>Individual Máster 50 masculino:</strong> Conforma esta categoría, todos los participantes masculinos que tengan 50 años o más a 31 de diciembre de 2025</li>
                <li><strong>Individual Máster 60 masculino:</strong> Conforma esta categoría, todos los participantes masculinos que tengan 60 años ó más a 31 de diciembre de 2025</li>
                <li><strong>Individual femenino:</strong> Conforma esta categoría, todas las participantes femeninas, independientemente de su edad.</li>                
                <li><strong>E-Bike masculino:</strong> Conforma esta categoría todos los participantes masculinos con bicicleta eléctrica independientemente de su edad.</li>                
                <li><strong>E-Bike femenino:</strong> Conforma esta categoría todos los participantes femeninos con bicicleta eléctrica independientemente de su edad</li>
              </ul>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-3 categoria-type">
              <h5>PRUEBA 1 DÍA (DOMINGO 14 ABRIL)</h5>
            </div>
            <div className="col-12 col-md-9 categoria-description">
              <ul>
                <li><strong>Individual masculino:</strong> Conforma esta categoría todos los participantes masculinos que tengan 18 o más años a a 31 de diciembre de 2025.</li>
                <li><strong>Individual femenino:</strong> Conforma esta categoría todas las participantes femeninas que tengan 18 o más años a a 31 de diciembre de 2025.</li>                
                <li><strong>E-Bike masculino:</strong> Conforma esta categoría todos los participantes masculinos que tengan 18 o más años a a 31 de diciembre de 2025 y compitan con BICI ELÉCTRICA.</li>                
                <li><strong>E-Bike femenino:</strong> Conforma esta categoría todos los participantes masculinos que tengan 18 o más años a a 31 de diciembre de 2025 y compitan con BICI ELÉCTRICA.</li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {(matches) =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default CategoriasPage;
