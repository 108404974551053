import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Image2 from './image2.jpg';
import './RatesPage.css';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import HeaderMobile from '../_components/HeaderMobile';
import { Link } from 'react-router-dom';

class RatesPage extends React.Component {
  render() {
    return (
      <div className='Rates'>
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className='Rates-hero'
          style={{
            height: '150px',
            width: '100%',
          }}></section>
        <section className='Rates-info'>
          <div className='container-fluid'>
          <div className="row no-gutters">
      <div className="col-12">
        <section className="tarifas">
          <div className="row no-gutters">
            <div className="col-12 col-md-7">
              <div className="lista-tarifas">
                <h4 className="tarifas-title">Tarifas</h4>
                <h6>
                Las inscripciones abrirán el 
                  <strong> 1 de diciembre de 2024 a las 20:00 horas</strong> <br />
                  <br />
                  El precio de inscripción va variando en función del nº de
                  inscritos según se especifica:{" "}
                </h6>
                <h5 className="mb-3 periodo-title"><strong>PLAZAS POR PERIODO</strong></h5>
                <ul>
                  <li>
                    <strong>Primer Período</strong> Desde el 1 de noviembre 2024 hasta el 31 de diciembre 2024 o completar las primeras 150 plazas disponibles.
                  </li>
                  <li>
                    <strong>Segundo Período</strong> Del 1 de enero 2025 hasta el 26 de marzo 2025 o completar las 400 plazas disponibles. En caso de completar las plazas del periodo anterior antes de su fecha límite, comenzará antes este periódico.
                  </li>
                </ul>
                <h5 className="mb-3 periodo-title"><strong>TARIFAS COSTA ATLÁNTICA COMPLETA (3 Días)</strong></h5>
                <ul>
                  <li>
                    <strong>109€ PRECIO ESPECIAL por corredor</strong> en el primer periodo.
                  </li>
                  <li>
                    <strong>129€ corredor</strong> en el segundo periodo
                  </li>
                </ul>
                <h5 className="mt-2 mb-3 periodo-title"><strong>TARIFAS COSTA ATLÁNTICA ÚLTIMO DÍA (solo se corre el domingo 6 de abril)</strong></h5>
                <ul>
                  <li>
                    <strong>40€ corredor</strong> en el primer periodo
                  </li>
                  <li>
                    <strong>45€ corredor</strong> en el segundo periodo
                  </li>
                </ul>
                  <small>
                    ***Todo corredor que no posea LICENCIA FEDERATIVA debera abonar 36€ por la licencia de día (3 días) o 12€ (1 día)
                  </small>
                <br/>
                  <small>
                    ***la tarifa se incrementa 9.5€/4.5€ (según si se coge la prueba total o último día) en caso de elegir la opción de <Link to='/seguro-devolucion'>“SEGURO DE DEVOLUCIÓN”</Link> opcional.
                  </small>
                <br/>
                  <small>
                    ***la tarifa se incrementa 25€ en caso de querer la sudadera oficial de la prueba, 35 euros en caso de querer el chaleco conmemorativo, un culotte NEGRO con un precio de 55€ y un culotte VERDE con un precio de 59€.
                  </small>
                <br/>
                <h6 className="mt-3">
                  <strong>
                    Todo participante inscrito para poder obtener la devolución de su inscripción deberá marcar en la ficha de inscripción la opción “SEGURO DE DEVOLUCIÓN”. Aquellos participantes que no se acojan a este seguro, no tendrán derecho a la devolución de su inscripción en ninguna de las circunstancias.
                  </strong>
                </h6>
                <div className='Rates-inscription-button-wrapper'>
                    <a
                      className='Rates-inscription-button'
                      href='#'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <FormattedMessage
                        id='rates.enroll'
                        defaultMessage='Inscríbete'
                      />
                    </a>
                  </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="que-incluye">
                <h6>La inscripción  de la prueba COMPLETA (3 días) incluye</h6>
                <ul>
                  <li>Maillot Oficial del evento: <strong>para los inscritos antes del 10 de marzo.</strong></li>
                  <li>Atención al participante</li>
                  <li>Derecho de participación en la competición.</li>
                  <li>Placa numerada.</li>
                  <li>Obsequios de inscripción</li>
                  <li>Regalo Finisher (si se logra).</li>
                  <li>Dispositivo electrónico para el control de tiempos.</li>
                  <li>Cronometraje</li>
                  <li>Marcaje de recorrido y personal.</li>
                  <li>Vehículos escoba.</li>
                  <li>Avituallamientos líquidos y sólidos.</li>
                  <li>Pasta party en la 2º, 3º etapa (sábado y domingo)</li>
                  <li>Asistencia médica dentro y fuera del recorrido.</li>
                  <li>Asistencia mecánica en avituallamiento y final de prueba</li>
                  <li>Área de lavado de bicicletas.</li>
                  <li>Aparcamiento de bicicletas.</li>
                  <li>Vestuarios, aseos y duchas (en caso de que los protocolos COVID así lo permitan)</li>
                  <li>Otros servicios gratuitos en el Paddock.</li>
                </ul>
                <h6 className="mt-5">La inscripción de la prueba ÚLTIMO DÍA (domingo 6) incluye</h6>
                <ul>
                  <li>Atención al participante</li>
                  <li>Derecho de participación en la competición.</li>
                  <li>Placa numerada.</li>
                  <li>Obsequios de inscripción</li>
                  <li>Regalo Finisher (si se logra).</li>
                  <li>Dispositivo electrónico para el control de tiempos.</li>
                  <li>Cronometraje</li>
                  <li>Marcaje de recorrido y personal.</li>
                  <li>Vehículos escoba.</li>
                  <li>Avituallamientos líquidos y sólidos.</li>
                  <li>Pasta party en la 3º etapa (domingo)</li>
                  <li>Asistencia médica dentro y fuera del recorrido.</li>
                  <li>Asistencia mecánica en avituallamiento y final de prueba</li>
                  <li>Área de lavado de bicicletas.</li>
                  <li>Aparcamiento de bicicletas.</li>
                  <li>Vestuarios, aseos y duchas (en caso de que los protocolos COVID así lo permitan)</li>
                  <li>Otros servicios gratuitos en el Paddock.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {(matches) =>
            matches ? (
              ''
            ) : (
              <section className='inscription-fixed-bar'>
                <Button
                  className='inscription-fixed'
                  href='https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2024'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default RatesPage;
